.gcb-card-message-row {
  margin-top: 10px;
  position: relative;

  .gcb-card-message-box {
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 230px;
    max-width: 100%;
    margin-bottom: 0.1em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.6px 0.54px -1.33px, rgba(0, 0, 0, 0.13) 0px 2.29px 2.06px -2.67px,
      rgba(0, 0, 0, 0.04) 0px 10px 9px -4px;
    border: 1px solid #80808024;

    img {
      overflow: hidden;
      width: 100%;
      height: 150px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .gcb-card-text-box {
      padding: 5px;
      color: rgb(110, 110, 110);

      .c-head-div {
        padding: 5px 6px;

        .c-head {
          margin: 0;
          font-weight: 500;
        }

        .c-sub {
          margin: 0;
        }
      }
    }

    .gcb-l-answer-box-btn {
      background-color: #f3f3f3;
      padding: 10px;
      margin-top: 4px;
      border-radius: 8px;
      text-align: center;
      color: #0a0a0c;
      cursor: pointer;
      font-weight: 700;
    }

    .gcb-l-answer-box-btn:hover {
      background-color: #bebebe;
    }
  }

  .gcb-q-card-icons {
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
    color: rgb(17, 17, 17);
  }

  .gcb-q-card-icons:hover {
    background-color: rgba(128, 128, 128, 0.205) !important;
  }

  .gcb-right-l-btn {
    float: left;
  }

  .gcb-right-r-btn {
    float: right;
  }
}
