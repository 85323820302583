.gcb-answer-box-btn {
  background-color: #8080801f;
  padding: 10px;
  margin-top: 4px;
  border-radius: 8px;
  text-align: center;
  color: #009de2;
  font-weight: 500;
  cursor: pointer;
}
