.mobile-screen-chat-box {
  width: 100%;
}

.large-screen-chat-box {
  position: absolute;
  right: 20px;
  bottom: 20px;
  min-width: 400px;
  max-width: 400px;
  border-radius: 16px;

  .gcb-chat-header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .gcb-chat-input {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.gcb-chat-box {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.48px 2.41px -0.38px, rgba(0, 0, 0, 0.17) 0px 4px 20px -0.75px;
  background: rgb(246, 246, 247);

  .gcb-chat-header {
    background-color: #2196f3;
    // border-bottom: 1px solid rgba(128, 128, 128, 0.486);
  }

  .gcb-top-btn {
    min-height: 50px;
  }

  .gcb-chat-close-button {
    background-color: transparent;
    color: white;
    border: none;
    box-shadow: none;
  }

  .float-close-btn {
    margin-top: 10px;
    margin-right: 10px;
    float: right;
  }

  .gcb-chat-close-button:hover {
    color: gray !important;
    background-color: white !important;
  }

  .gcb-chat-title {
    text-align: center;
    padding-bottom: 15px;
    color: white;

    img {
      max-width: 30px;
    }

    h1 {
      font-size: 1.2rem;
      margin-bottom: 0;
    }

    p {
      margin: 0;
      color: white;
    }
  }

  .min-header {
    text-align: center;
    margin: auto;

    img {
      max-width: 15px;
      display: inline;
      margin-right: 10px;
    }

    h1 {
      font-size: 1.2rem;
      margin-bottom: 0;
      display: inline;
    }
  }

  .gcb-chat-container {
    height: 400px;
    overflow-y: auto;
    padding: 15px;
    background-color: white;
    border-bottom: 1px solid rgba(128, 128, 128, 0.486);

    .gcb-chat-item {
      font-size: 14px;
      margin-bottom: 8px;

      .text-area {
        p {
          padding: 10px;
          border-radius: 8px;
          margin: 0;
          display: inline-block;
        }
      }
    }

    .left-message {
      .text-area {
        p {
          background-color: #1972f5;
          color: white;
        }
      }

      .text-loading-area {
        background-color: white;
        border-radius: 8px;
        text-align: center;
      }
    }

    .right-message {
      .text-area {
        p {
          background-color: #f0f2f5;
          color: #1c293b;
          float: inline-end;
        }
      }
    }

    .img-area {
      img {
        border-radius: 8px;
        max-width: 100%;
        max-height: 250px;
      }
    }
  }

  .gcb-chat-input {
    background-color: white;
    padding: 5px;

    .gcb-input-col {
      display: 'inline-flex';
      justify-content: 'center';
      align-items: 'center';
    }

    .text-input {
      border: none;
      width: 100%;
    }

    .text-input:focus {
      border: none;
      width: 100%;
    }

    .gcb-send-message-btn {
      background-color: transparent;
      color: gray;
      border: none;
      box-shadow: none;
      float: right;
    }

    .gcb-send-message-btn:hover {
      background-color: rgba(128, 128, 128, 0.205) !important;
    }
  }
}
