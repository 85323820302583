.gcb-icon-div {
  position: fixed;
  right: 15px;
  bottom: 15px;

  .gcb-icon-button {
    background-color: #1972f5;
    width: 55px;
    height: 55px;
  }
}
