body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  min-height: 100px;
  /* margin: 0; */
  /* background-image: url('https://res.cloudinary.com/template-generator/image/upload/v1705162990/vida.preconstructionreviews.com/2_ibqprz.webp'); */
  /* background-repeat: no-repeat;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}
